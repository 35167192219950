import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import FluidImage from '../../components/FluidImage'
import Layout from '../../components/Layout'
import { Box } from '../../components/Shared'
import UniversalLink from '../../components/UniversalLink'
import backgroundOrangeYellowPink from '../../images/background-orange-yellow-pink.png'
import backgroundTealBluePinkPath from '../../images/background-teal-blue-pink.png'
import PlatformLinks from './platformLinks'

const PodcastHero = styled(Box)`
  position: relative;
`

const SubscribeCTA = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  padding: 40px;
  right: 0px;
  top: 32px;
  text-align: left;

  span {
    text-align: center;
    font-size: 3.5rem;
    margin-bottom: 48px;
  }

  a {
    font-size: 2rem;
    color: #000;

    &:hover {
      background-color: #ffc900;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints[1]}) {
    display: none;
  }
`

const EpisodeGrid = styled(Box)`
  background-image: url(${backgroundTealBluePinkPath});
  background-size: cover;
  padding: 10vw;
`

const EpisodeDescription = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 350px;

  span:nth-child(3) {
    margin: 12px 0;
  }
  * {
    font-size: 1rem;
    font-weight: 600;
  }

  h2,
  span:nth-child(1) {
    font-size: 1.3rem;
  }

  text-align: center;
  background-color: #ffc900;
  padding: 24px;
  text-transform: uppercase;
`

const LinkWrapper = styled(UniversalLink)`
  display: block;
  cursor: pointer;
  text-decoration: none;

  white-space: nowrap;

  text-transform: 'uppercase';

  text-align: center;

  padding: 24px 32px;

  letter-spacing: 0.08rem;
  font-weight: 600;
  font-size: 0.9rem;

  transition: all 0.3s ease;

  background: #000;
  color: #fff;

  &:disabled {
    cursor: not-allowed;
  }

  &:after {
    display: inline-block;
    content: '\\2192'; // arrow right unicode
    font-weight: bold;
    transition: all 0.3s ease-out;
    color: transparent;
  }

  &:hover {
    text-decoration: underline;

    &:after {
      padding: 0 8px;
      transform: translateX(5px);
      color: white;
    }
  }

  text-decoration: none;
  text-transform: uppercase;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    white-space: nowrap;
  }
`

const EpisodeWrapper = styled(Box)`
  position: relative;

  a {
    text-decoration: none;
  }
`

const CallToJoin = styled(Box)`
  background-image: url(${backgroundOrangeYellowPink});
  background-size: cover;
  padding: 200px 80px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  h2 {
    font-size: 2.5rem;
    max-width: 400px;
    text-align: center;
  }
`

const Listing = ({ pageContext }) => {
  const episodes = pageContext.wpPodcasts.map((node) => ({
    ...node.pageBuilder.layouts[0],
    uri: node.uri,
  }))
  console.log('💩 ~ file: listing.js:158 ~ episodes', episodes)

  return (
    <Layout>
      <PodcastHero fontFamily="oswald">
        <StaticImage src="../../images/podcastHero.png" />
        <SubscribeCTA>
          <StaticImage src="../../images/hosted-by.png" width={400} />
          <Box display="flex" flexDirection="column">
            <span>EMAN ISMAIL</span>
          </Box>
        </SubscribeCTA>
      </PodcastHero>
      <PlatformLinks />
      <EpisodeGrid
        display="grid"
        gridTemplateColumns={[
          'repeat(1, 1fr)',
          'repeat(2, 1fr)',
          'repeat(3, 1fr)',
        ]}
        gridGap={5}
      >
        {episodes
          .sort((a, b) => (a.episodeNumber < b.episodeNumber ? 1 : -1))
          .map((episode) => {
            const { episodeCover, guestName, heading, episodeNumber } = episode

            return (
              <>
                <EpisodeWrapper key={heading}>
                  <FluidImage
                    image={episode.episodeCover}
                    alt={
                      episodeCover?.altText ||
                      `Episode cover for mistakes that made me with ${guestName}`
                    }
                  />
                  <EpisodeDescription>
                    <span>#{episodeNumber || 0}: </span>
                    <div dangerouslySetInnerHTML={{ __html: heading }} />
                    <span>WITH</span>
                    <span>{guestName}</span>
                    <br />
                    <LinkWrapper to={episode.uri}>LISTEN NOW</LinkWrapper>
                  </EpisodeDescription>
                </EpisodeWrapper>
              </>
            )
          })}
      </EpisodeGrid>
      <CallToJoin
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <h2>WANNA BE A GUEST ON THE PODCAST?</h2>
        <LinkWrapper to="https://hello.dubsado.com/public/form/view/62f004f083c5840522e0d0dd">
          APPLY HERE
        </LinkWrapper>
      </CallToJoin>
    </Layout>
  )
}

export default Listing
